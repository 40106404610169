import React from 'react';
import { NavLink } from 'react-router-dom';
import './TopNav.css';

const TopNav = () => {
    return (
        <nav className="top-nav">
            <ul>
                <li>
                    <NavLink to="/" className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')} end>Home</NavLink>
                </li>
                <li>
                    <NavLink to="/about" className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')}>Info</NavLink>
                </li>
                <li>
                    <NavLink to="/projects" className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')}>Projects</NavLink>
                </li>
                <li>
                    <NavLink to="/contact" className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')}>Contact</NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default TopNav;