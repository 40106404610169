import React from 'react';
import './Pages.css';

const Projects = () => {
    return (
        <div className="Page">
            <header className="Page-header">
                <div className="Text-Block">
                    <h2>🚀 Projects:</h2>
                    <p>
                    Here are some of the projects I've been working on:<br/>
                    
                    <a href="https://github.com/AryveN/Calendary">Calendary</a>: A calendar and event management application built in Swift, aimed at simplifying event organization and scheduling.<br/>
                    <a href="https://github.com/AryveN/AryskovoBakalari">AryhoBakaláři</a>: Created my own adaptation of the Bakaláři application as a school project, focusing on user experience and functionality.<br/>
                    <a href="https://github.com/AryveN/MultiPurposeBot">MultiPurposeBot</a>: Custom bot for my friend. It consists of ticket system, join & leave messages, adding upon a server and reaction role system.<br/>
                    <a href="https://github.com/AryveN/AryskovoMPB">AryskovoMPB</a>: 🚧 WIP project of my modular discord bot in Java.<br/>
                    <b>Discord Bots</b>: Developed several Discord bots using Java and JavaScript to enhance server interactions and provide various utilities.<br/>
                    <b>Websites for Various Occasions</b>: Created multiple web pages tailored for different events, showcasing skills in web design and development.<br/>
                    </p>
                </div>
            </header>
        </div>
    );
};

export default Projects;