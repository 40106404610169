import React from 'react';

const Contact = () => {
    return (
        <div className="Page">
            <header className="Page-header">
                <div className="Text-Block">
                    <h2>💬 Contacts</h2>
                    <p>
                    Feel free to connect with me if you want to collaborate or chat about programming, networking, or anything tech-related! You can reach me on: <br/>

                    <ul>
                    <li><a href="https://www.linkedin.com/in/kryštof-hugo-malý-94b164217/">LinkedIn</a></li>
                    <li>Discord : arysek</li>
                    <li>Instagram: ary.sek </li>   
                    </ul>                
                    </p>
                </div>
            </header>
        </div>
    );
};

export default Contact;